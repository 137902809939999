$(document).on('turbo:load', function () {
  $('.subscription-banner').on('click', '.dismiss-btn', function () {
    removeSubscriptionBanner()
  })

  $('#visible_subscription_form').on('submit', function () {
    visibleSubscriptionFormSubmission()
  })
})

// Display post submission
function displayPostSubmission () {
  const subscriptionBanner = $('.subscription-banner.form-container')
  const postSubscriptionBanner = $('.subscription-banner.post-form-container')

  subscriptionBanner.hide()
  postSubscriptionBanner.show()
}

// Copy emailValue into the Hubspot form, submit the Hubspot form
// @param emailVal [Value] subscription email
function hubspotSubscriptionFormSubmission (emailVal) {
  if (emailVal === '') { return }

  // Always grab iframe first, otherwise cannot find the elements inside the iframe
  const iframe = document.querySelector('#hubspot_subscription_form iframe')
  if (iframe == null) { return }

  // Get document content in the iframe
  let iframeDocument = iframe.contentDocument || iframe.contentWindow.document
  iframeDocument = $(iframeDocument)
  const emailField = iframeDocument.find("input[type='email']")
  const form = iframeDocument.find('form')
  emailField.val(emailVal)
  form.submit()
}

// Submit visible subscription form
function visibleSubscriptionFormSubmission () {
  const form = $('#visible_subscription_form')
  const emailField = form.find("input[type='email']")
  const emailVal = emailField.val().toString().trim()
  if (emailVal === '') { return }

  // Copy emailValue into the Hubspot form, submit the Hubspot form
  hubspotSubscriptionFormSubmission(emailVal)

  // Display post submission
  displayPostSubmission()
}

// Remove subscription banner
function removeSubscriptionBanner () {
  const subscriptionBanner = $('.subscription-banner')
  const footerSectionExtraPadding = $('footer section .add-padding')

  subscriptionBanner.removeClass('anim-slide-up-in')
  subscriptionBanner.addClass('anim-slide-down-out')
  subscriptionBanner.on('animationend', function () {
    subscriptionBanner.remove()
    footerSectionExtraPadding.remove()
  })
}
