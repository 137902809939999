// Displaying image in the data-ujs-id="display_target_image" section without saving it
$(document).on('change', '.person-image', function (e) {
  const files = e.target.files
  const image = files[0]
  const reader = new FileReader()
  const target_image_section = $(this).parents('.update-person-container').find('.display-target-image')
  reader.onload = function (file) {
    const img = new Image()
    img.src = file.target.result
    target_image_section.html(img)
  }
  reader.readAsDataURL(image)
})

$(document).on('click', '.cancel-bio-update', function () {
  $(this).parents('.bio-container').find('.update-bio-btn-container').show()
  $(this).parents('.team-member-create-update-form').remove()
  $('.add-team-member').show()
  $('.new-team-member-container').parents('.update-bio-container').hide()
})
