// if you click on the scroll_to_top arrow, it will take you to the top of the page
$(document).on('click', '.scroll-top', function () {
  $('html, body').animate({ scrollTop: 0 }, 'slow')
})

// Toggle scroll_to_top arrow during scrolling
$(window).scroll(function (e) {
  const arrow = $('.scroll-top')
  const visible = arrow.is(':visible')
  const top = $(window).scrollTop()
  const boundary = $('header').height()

  // Hide arrow, when scroll to the top of page
  if (top <= boundary && visible === true) {
    arrow.fadeOut('fast')
  // Show arrow, when scroll down the page
  } else if (top > boundary && visible === false) {
    arrow.fadeIn('fast')
  }
})
