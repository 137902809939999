$(document).on('turbo:load', function () {
  if ($('#blog_posts_index').length > 0) {
    const blogPosts = $('[data-blog-post=\'blog_post\']')
    const gridIconActive = $('#grid_view_icon_active')
    const gridIconInactive = $('#grid_view_icon_inactive')
    const listIconActive = $('#list_view_icon_active')
    const listIconInactive = $('#list_view_icon_inactive')
    const gridClass = 'col-4 grid-column-view'
    const listClass = 'col-12 border-left list-view'

    $('#blog_posts_index').on('click', '#grid_view', function () {
      blogPosts.removeClass(listClass)
      blogPosts.addClass(gridClass)
      gridIconActive.removeClass('hidden')
      gridIconInactive.addClass('hidden')
      listIconInactive.removeClass('hidden')
      listIconActive.addClass('hidden')
    })

    $('#blog_posts_index').on('click', '#list_view', function () {
      blogPosts.removeClass(gridClass)
      blogPosts.addClass(listClass)
      listIconActive.removeClass('hidden')
      listIconInactive.addClass('hidden')
      gridIconInactive.removeClass('hidden')
      gridIconActive.addClass('hidden')
    })
  }

  $('#blog_posts_edit').on('change', '.blog-format-option', function () {
    toggleExternalFields($(this))
  })

  $('#blog_posts_update').on('change', '.blog-format-option', function () {
    toggleExternalFields($(this))
  })

  $('#blog_posts_edit').on('change', '#file_upload', function () {
    uploadFile(this)
  })

  $('#blog_posts_update').on('change', '#file_upload', function () {
    uploadFile(this)
  })

  $('#blog_posts_edit').on('click', '#remove-container', function () {
    removeUploadedFile()
  })

  $('#blog_posts_update').on('click', '#remove-container', function () {
    removeUploadedFile()
  })
})

/**
 * Remove the uploaded file from the file input field, reset the upload button, re-enable gallery and select first image
 * This method also hides and displays corresponding HTML elements to enable gallery selection
 */
function removeUploadedFile () {
  const firstGalleryId = '#' + $('input[id^=gallery_image_]')[0].id
  const firstGalleryImage = $(firstGalleryId)
  const fileUplaod = $('#file_upload')
  const previewImage = $('#preview_image')
  const placeholder = $('#preview_image_placeholder')
  const imageButtons = $('.image-buttons')
  const imageGallery = $('#image-gallery-container')
  const customLabel = $('#custom_label')
  const uploadTipText = $('.upload-tip-text')
  const removeTipText = $('#remove-tip-text')

  fileUplaod.val('')
  previewImage.attr('src', '')
  previewImage.addClass('hidden')
  placeholder.removeClass('hidden')
  imageButtons.prop('disabled', false)
  imageGallery.removeClass('disabled-container')
  customLabel.removeClass('custom-selected')
  firstGalleryImage.prop('checked', true)
  uploadTipText.removeClass('hidden')
  removeTipText.addClass('hidden')
}

/**
 * Read the uploaded file and preview the image as a thumbnail, disabled gallery image input, select radio button for custom image
 * This method also hides and displays corresponding HTML elements to disable gallery selection
 * @param {HTMLInputElement} input Input uploaded by user containing image file for processing
 */
function uploadFile (input) {
  if (input.files && input.files[0]) {
    const reader = new FileReader()
    const previewImage = $('#preview_image')
    const placeHolder = $('#preview_image_placeholder')
    const customImage = $('#custom_image')
    const customLabel = $('#custom_label')
    const imageButtons = $('.image-buttons')
    const imageGallery = $('#image-gallery-container')
    const uploadTipText = $('.upload-tip-text')
    const removeTipText = $('#remove-tip-text')

    reader.onload = function (file) {
      previewImage.attr('src', file.target.result)
      previewImage.removeClass('hidden')
      placeHolder.addClass('hidden')
      customImage.prop('checked', true)
      customLabel.addClass('custom-selected')
      imageButtons.prop('disabled', true)
      imageGallery.addClass('disabled-container')
      uploadTipText.addClass('hidden')
      removeTipText.removeClass('hidden')
    }

    reader.readAsDataURL(input.files[0])
  }
}

/**
 * Hide and reveal elements (external link, body, and upload) on the blog edit page, depending on the element value
 * Additionally, this function will toggle the page title and if the external link is a required field
 * @param {HTMLElement} elem radio button element that indicates if the blog post has an external link or not
 */
function toggleExternalFields (elem) {
  const blogFormat = elem.val()
  const externalLink = $('.external-link')
  const externalLinkField = $('#blog_post_external_link')
  const blogBody = $('.blog-body')
  const attachedPdf = $('#pdf_title_text')
  blogUploadButton = $('#blog_post_pdf'),
  blogUploadClass = $('.blog-upload'),
  blogHeader = $('#blog_post_page_header'),
  fromCreateNewPost = blogHeader.data('from-create-new-post'),
  deletePdfButton = $('#delete_pdf_button')

  // Toggle visibility of fileds based on if the post is an external article
  if (blogFormat === 'external_link') {
    externalLink.removeClass('hidden')
    blogBody.addClass('hidden')
    blogUploadClass.addClass('hidden')
    blogUploadButton.prop('required', false)

    externalLinkField.prop('disabled', false)
    externalLinkField.prop('required', true)
    var headerText = fromCreateNewPost ? 'Create External Article' : 'Edit External Article'
    blogHeader.html(headerText)
  } else if (blogFormat === 'standard') {
    externalLink.addClass('hidden')
    deletePdfButton.removeClass('hidden')
    blogBody.removeClass('hidden')
    blogUploadClass.removeClass('hidden')
    blogUploadButton.prop('required', false)

    // We disable the external link field when switch from external_link because chrome will still try to
    // validate that the field is a URL, even if it is not required. It will ignore validation when the field is disabled.
    externalLinkField.prop('disabled', true)
    externalLinkField.prop('required', false)

    var headerText = fromCreateNewPost ? 'Create New Article' : 'Edit HTML Article'
    blogHeader.html(headerText)
  } else if (blogFormat === 'pdf') {
    externalLink.addClass('hidden')
    blogBody.addClass('hidden')
    blogUploadClass.removeClass('hidden')
    if (attachedPdf.length != 0) {
      blogUploadButton.prop('required', false)
    } else {
      blogUploadButton.prop('required', true)
    }
    deletePdfButton.addClass('hidden')

    // We disable the external link field when we switch from external_link because chrome will still try to
    // validate that the field is a URL, even if it is not required. It will ignore validation when the field is disabled.
    externalLinkField.prop('disabled', true)
    externalLinkField.prop('required', false)

    var headerText = fromCreateNewPost ? 'Create PDF Article' : 'Edit PDF Article'
    blogHeader.html(headerText)
  }
}
