$(document).on('turbo:load', function () {
  // When a dropdown is clicked, add a visible class to alter css
  $('.dropdown').click(function () {
    $(this).toggleClass('dropdown-visible')
  })
})

// Function to toggle mobile css.
window.toggleMobileNavMenu = function toggleMobileNavMenu () {
  $('#myHeadernav').toggleClass('is-mobile-navbar-visible')
}

// Function to allow navbar navigation with keyboard
document.addEventListener('turbo:load', function () {
  const dropdowns = document.querySelectorAll('.dropdown')

  dropdowns.forEach(dropdown => {
    const content = dropdown.querySelector('.dropdown-content')
    const focusableElements = content.querySelectorAll('a[href]')
    const firstFocusableElement = focusableElements[0]
    const lastFocusableElement = focusableElements[focusableElements.length - 1]

    dropdown.addEventListener('keydown', function (e) {
      const focusedElement = document.activeElement
      // If the focused element is a link and Enter is pressed, let the default behavior occur (navigate to the link)
      if (focusedElement.tagName === 'A' && e.key === 'Enter') {
        return
      }
      // Open dropdown on 'Enter', 'Space', or 'ArrowDown' key
      if (e.key === 'Enter' || e.key === ' ' || e.key === 'ArrowDown') {
        e.preventDefault()
        if (!content.classList.contains('open')) {
          content.classList.add('open')
          dropdown.classList.add('open')
          dropdown.setAttribute('aria-expanded', 'true')
          focusableElements[0].focus() // Focus the first element in the dropdown
          return // Important to return here to avoid further ArrowDown logic below
        }
      }
      // Close dropdown on 'Escape' key
      if (e.key === 'Escape') {
        content.classList.remove('open')
        dropdown.classList.remove('open')
        dropdown.setAttribute('aria-expanded', 'false')
        dropdown.focus()
      }
      // Navigation using arrow keys within dropdown when it's opened
      if (content.classList.contains('open')) {
        if (e.key === 'ArrowDown') {
          e.preventDefault()
          const index = Array.from(focusableElements).indexOf(focusedElement)
          const nextElement = focusableElements[index + 1] || focusableElements[0] // Loop back to the first item if at the end
          nextElement.focus()
        }
        if (e.key === 'ArrowUp') {
          e.preventDefault()
          const index = Array.from(focusableElements).indexOf(focusedElement)
          const previousElement = focusableElements[index - 1] || focusableElements[focusableElements.length - 1] // Loop back to the last item if at the start
          previousElement.focus()
        }
      }
    })
    // hides dropdown content if users clicks 'TAB' while on last element in dropdown
    lastFocusableElement.addEventListener('blur', function (e) {
      setTimeout(() => {
        if (!dropdown.contains(document.activeElement)) {
          content.classList.remove('open')
          dropdown.classList.remove('open')
          dropdown.setAttribute('aria-expanded', 'false')
        }
      }, 0)
    })
    // sets focus back to dropdown and hides content if user clicks 'SHIFT + TAB' while on first focusable element
    firstFocusableElement.addEventListener('blur', function (e) {
      if (e.relatedTarget === dropdown || !document.contains(e.relatedTarget)) {
        content.classList.remove('open')
        dropdown.classList.remove('open')
        dropdown.setAttribute('aria-expanded', 'false')
        dropdown.focus()
      }
    })
  })
})
