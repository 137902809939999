$(document).on('click', '[data-trix-video-insert]', function (e) {
  e.preventDefault()
  const element = $(e.currentTarget)
  const editor = element.parent().siblings('trix-editor').first()[0].editor
  const url = prompt('Enter the URL for the vimeo video.')
  const attachment = generate_attachment(url)

  if (attachment) {
    editor.insertAttachment(attachment)
  } else {
    alert('The value provided does not appear to be a valid vimeo URL.')
  }
})

function generate_attachment (url) {
  try {
    var url = new URL(url)
    // We should only accept vimeo URLs
    if (url.hostname === 'www.vimeo.com' || url.hostname === 'vimeo.com') {
      const embed_url = 'https://player.vimeo.com/video/' + url.pathname.split('/')[1] + '?title=0&byline=0&portrait=0&dnt=1'
      return new Trix.Attachment(
        {
          content: '<div class="vimeo-container">' +
            '<iframe class="vimeo-embed" src="' +
            embed_url +
            '" frameborder="0" allow="autoplay; fullscreen" allowfullscreen>' +
            '</iframe></div>'
        })
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}
