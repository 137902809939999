$(document).on('turbo:load', function () {
  // When a video is playing
  $('.media-desc-container video').on('play', function () {
    const currentVideo = $(this)
    const container = currentVideo.parents('.media-desc-container')
    const allVideos = container.find('video')

    // currentVideo is a jQuery object, .get(0) will get a video
    const currentVideoObj = currentVideo.get(0)
    pauseRestVideos(currentVideoObj, allVideos)
  })
})

// Pause all videos except current video
// @param currentVideo [Video] current video
// @param allVideos [Array<Video>] all videos, including current video
function pauseRestVideos (currentVideo, allVideos) {
  allVideos.each(function (_i, video) {
    // Pause a video is not paused and it is not a current video
    if (!video.paused && video != currentVideo) {
      video.pause()
    }
  })
}
