$(document).on('turbo:load', function () {
  toIndicesSectionBasedOnUrl()
})

$(document).on('click', '.accordion-header.caret', function () {
  toggleAccordionSections(this)
})

/**
 * Scroll to and toggle to the appropriate accordion section
 * based on current page's URL
 */
function toIndicesSectionBasedOnUrl () {
  const onIndicesPage = $('#syntax_indices_index').length > 0
  // Check if it's on Indices page
  if (!onIndicesPage) return

  const href = window.location.href
  // If there is no section to scroll to in the URL, return
  if (!href.includes('#')) return

  const sectionId = hrefToSelector(href)
  const section = $(sectionId)

  // Check if selected element exists on the page
  if (section.length === 0) return

  scrollAndToggle(section)
}

/**
 * Parse the URL to get the first value after '#'
 * @parm {String} href URL
 */
function hrefToSelector (href) {
  return '#' + href.split('#').pop()
}

/**
 * Scroll to and toggle the appropriate accordion section
 * @param {HTMLElement} section
 */
window.scrollAndToggle = function scrollAndToggle (section) {
  const targetSection = section.siblings('div')
  scrollToTarget(section)

  // don't toggle visibililty if the target section is already open
  if (targetSection.attr('style') != 'display: block;') {
    toggleAccordionSections(section)
  }
}

/**
 * Scroll to the appropriate target accordion section
 * @param {HTMLElement} section
 * @return {jQueryObject}
 */
function scrollToTarget (section) {
  const navBarOffset = 100
  const targetSection = section.siblings('div')
  const targetSectionOrder = targetSection.data('sectionOrder')
  const targetSectionOffset = calculateAccordionSectionOffset(section, targetSectionOrder)
  $('html, body').animate({ scrollTop: targetSectionOffset - navBarOffset }, 'slow')
}

/**
 * Needed because of async code involving expanding/collasping sections
 * Offset is calculated before non-target sections are collapsed
 * @param {HTMLElement} targetSection section to expanded
 * @param {Integer} targetSectionOrder
 * @return {Integer} offset to scroll to target section
 */
function calculateAccordionSectionOffset (targetSection, targetSectionOrder) {
  const expandedSection = $('.accordion-section[style="display: block;"]')
  let targetSectionOffset = targetSection.offset().top

  // if there's an expanded section that comes BEFORE the target section,
  // subtract the height of the expanded section from the offset
  if (expandedSection.length > 0 && expandedSection.data('sectionOrder') < targetSectionOrder) {
    targetSectionOffset -= expandedSection.height()
  }
  return targetSectionOffset
}

/**
 * Toggle the given accordion section
 * @param {HTMLElement} section
 */
function toggleAccordionSections (section) {
  const targetSection = $(section).siblings('div')
  const accordionButton = $(section).find('button')
  const isExpanded = accordionButton.attr('aria-expanded') === 'true'

  accordionButton.attr('aria-expanded', !isExpanded)

  collapseAccordionSections('.accordion-section', targetSection)
  collapseAccordionHeaders('.accordion-header', section)

  $(section).find('svg').toggleClass('fa-caret-right')
  $(section).find('svg').toggleClass('fa-caret-down')
  targetSection.slideToggle()

  const delay = 375

  var timeOut = setTimeout(function () {
    scrollToTarget($(section))
    clearTimeout(timeOut)
  }, delay)
}

/**
 * Collapse each accordion section that is not the target
 * @param {String} sectionClassName class name for the sections
 * @param {HTMLElement} targetSection section that we are expanding
 */
function collapseAccordionSections (sectionClassName, targetSection) {
  $(sectionClassName).each(function (_index, section) {
    const accordionSection = $(section)

    // If the section is visible then we collapse the section.
    if (!(accordionSection.css('display') === 'none') && !accordionSection.is(targetSection)) {
      accordionSection.slideToggle()
    }
  })
}

/**
 * Collapse each accordion section header that is not the target
 * @param {String} sectionHeaderClassName class name for the section headers
 * @param {HTMLElement} targetSectionHeader section header that we are expanding
 */
function collapseAccordionHeaders (sectionHeaderClassName, targetSectionHeader) {
  $(sectionHeaderClassName).each(function (_index, sectionHeader) {
    const accordionSectionHeader = $(sectionHeader)

    // If the section is visible then we collapse the section.
    if (!accordionSectionHeader.is(targetSectionHeader)) {
      accordionSectionHeader.find('svg').toggleClass('fa-caret-right', true)
      accordionSectionHeader.find('svg').toggleClass('fa-caret-down', false)
    }
  })
}
