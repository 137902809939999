import * as d3 from 'd3'

// based on https://bl.ocks.org/mbostock/3887193
window.create_donut_chart = function create_donut_chart (container, text, radius, data) {
  const widthHeight = radius * 2
  const arc = d3.arc()
    .outerRadius(radius - 10)
    .innerRadius(radius - 70)

  const labelArc = d3.arc()
    .outerRadius(radius - 40)
    .innerRadius(radius - 40)

  const pie = d3.pie()
    .sort(null)
    .value(function (d) {
      return +d.percentage
    })

  const svg = d3.select(container).append('svg')
    .attr('width', widthHeight)
    .attr('height', widthHeight)
    .append('g')
    .attr('transform', 'translate(' + widthHeight / 2 + ',' + widthHeight / 2 + ')')

  svg.append('text')
    .attr('text-anchor', 'middle')
    .attr('dy', 6)
    .attr('font-size', '24px')
    .attr('fill', '#FFFFFF')
    .attr('font-weight', 500)
    .text(text)

  const g = svg.selectAll('.arc')
    .data(pie(data))
    .enter().append('g')
    .attr('class', 'arc')
    .on('mouseover', show_tooltip)
    .on('mouseout', hide_tooltip)

  g.append('path')
    .attr('d', arc)
    .attr('class', function (d) {
      return d.data.class
    })
}

// Mouseover event to show tooltip.
// @param p [Hash] a current mouseover node.
function show_tooltip (event, p) {
  const tooltip = $('.donut-chart-tooltip')

  tooltip.html(p.data.percentage + '%')
  tooltip.css({
    top: event.pageY,
    left: event.pageX
  })

  tooltip.show()
};

// Hide tooltip.
function hide_tooltip () {
  $('.donut-chart-tooltip').hide()
};
