// Video behavior is inconsistent between browsers - chrome will auto-pause vid
// when it goes offscreen, firefox will not. Since we use some CSS animations,
// we should keep their state synced with video state
$(document).on('turbo:load', function () {
  if ($('#static_pages_affinity').length > 0) {
    // Start CSS anims when video plays
    $('#affinity_video').on('play', function () {
      // Ensure CSS anims are running while video is playing
      $('.js-video-anim-sync').css('animation-play-state', 'running')
    })

    // Ensure CSS anims pause while video is paused
    $('#affinity_video').on('pause', function () {
      $('.js-video-anim-sync').css('animation-play-state', 'paused')
    })

    // Ensure CSS anims keep running once video completes
    $('#affinity_video').on('ended', function () {
      $('.js-video-anim-sync').css('animation-play-state', 'running')
    })
  }
})
