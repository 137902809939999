// This custom "plugin" allows a user to input an `alt` attribute for an image.

// Unfortunately, Trix has pretty much no documentation for how to
// extend it other than the source code and a really short implementation guide.

// So here's what I know so far:

// Creating a custom attachment, or changing the contents of an attachment
// is straightforward. Providing an UI for editing an attachment is not unless
// you can hook into Trix.

// Trix heavily utilizes the delegate pattern internally, but I can't seem
// to add additional delegates to fire additional events, so we're stuck with
// what it provides out of this box for now.
// Known events: trix-initialize, trix-attachment-add, trix-attachment-remove,
// trix-change (not triggered by changing captions, or selections),
// trix-action-invoke (triggered when using a custom toolbar function).

// Images, and pretty much every custom element you create programmatically
// should be created as an attachment.
// Attachments have a model (attributes) that is stored in the rendered content
// as a JSON string. This is saved on the server so the editor state and any
// custom attributes we store in an attachment can be restored. You should always
// update attributes on an attachment using setAttribute or setAttributes.
// There are some special attributes as well. If you set the `content` attribute,
// you can set the rendered HTML as it will appear on the page, and it will be
// shown in the preview.

// Trix uses the custom element API to create the trix-editor element.
// You can conveniently access the underlying trix instance by selecting the
// element and calling .editor on it. You may need to unwrap the element from jQuery.

function show_attachment_modal (attachment_element, attachment_modal) {
  // Hide the attachment modal in case its about to be moved, or won't be reopened.
  attachment_modal.addClass('hidden')

  // Set the information the modal will be using to operate.
  attachment_modal.data('trix-attachment-id', attachment_element.data('trix-id'))
  attachment_modal.data('trix-editor-id', attachment_element.closest('trix-editor').attr('trix-id'))
  // Lookup the attachment and editor.
  const attachment_editor = find_editor_by_id(attachment_modal.data('trix-editor-id'))
  const attachment = find_attachment_by_id(attachment_editor, attachment_modal.data('trix-attachment-id'))
  attachment_modal.find('input').val(attachment.getAttributes().alt_text)

  // Don't bring up the attachment modal if this isn't an attached image.
  // Vimeo links don't get the attachment modal.
  const rails_id = attachment.getAttributes().rails_attachment_id
  if (rails_id === null || typeof (rails_id) === 'undefined') return

  attachment_modal.removeClass('hidden')
  const modal_container = $('.menu-action-container')

  const attachment_postion = attachment_element.offset()
  const container_position = modal_container.offset()

  const attachment_width = attachment_element.outerWidth()
  const modal_width = attachment_modal.outerWidth()
  const modal_height = attachment_modal.outerHeight()
  // This positions the modal in the exact same spot as the image.
  const modal_position = {
    top: attachment_postion.top - container_position.top,
    left: attachment_postion.left - container_position.left
  }
  // We should update the position so that we horizontally center the modal relative to the image center.
  modal_position.left = modal_position.left + (attachment_width / 2) - (modal_width / 2)
  // We should move the modal up by its height so it doesn't cover the image + a little extra.
  modal_position.top = modal_position.top - modal_height - 15
  // Finally, position the element.
  attachment_modal.css(modal_position)
}

function hide_attachment_modal (attachment_modal) {
  attachment_modal.addClass('hidden')
  attachment_modal.removeData('trix-id')
  attachment_modal.removeData('trix-editor-id')
  attachment_modal.find('input').val('')
}

function update_attachment (input_field, attachment_modal) {
  const attachment_editor = find_editor_by_id(attachment_modal.data('trix-editor-id'))

  // If the attachment editor is null then we can skip updating the attachments
  if (attachment_editor === null) { return }

  const attachment = find_attachment_by_id(attachment_editor, attachment_modal.data('trix-attachment-id'))
  const attributes = attachment.getAttributes()
  const new_tag = '<img src="' + attributes.url +
    '" alt="' + input_field.val() +
    '" width="' + attributes.width +
    '" height="' + attributes.height +
    '" />'
  attachment.setAttributes({ alt_text: input_field.val(), content: new_tag })
}

function find_editor_by_id (editor_id) {
  const trixEditor = $('trix-editor[trix-id=' + editor_id + ']')

  // Check if there is a trix editor on the page and if not then return
  if (trixEditor.length <= 0) { return null }

  // Otherwise return the editor
  return trixEditor[0].editor
}

function find_attachment_by_id (editor, attachment_id) {
  const attachments = editor.composition.attachments
  for (let i = 0; i < attachments.length; i++) {
    if (attachments[i].id === parseInt(attachment_id)) {
      return attachments[i]
    }
  }
  return null
}

$(document).on('change', '#trix_attachment_modal input', function (event) {
  const input_field = $(event.currentTarget)
  const modal = input_field.closest('#trix_attachment_modal')
  update_attachment(input_field, modal)
})

// Trix may block certain events from propagating which makes it
// harder to find out when something happens, such as when a user selects an
// attachment. The attachment metadata tag blocks the propagation of the click event.
// These listeners work only because we hide that element from the page.
$(document).on('click', 'trix-editor [data-trix-attachment]', function (event) {
  const attachment = $(event.currentTarget)
  const attachment_modal = $('#trix_attachment_modal')
  // If there is a modal currently visible we should update it before continuing.
  if (!attachment_modal.hasClass('hidden')) {
    update_attachment($(attachment_modal.find('input')), attachment_modal)
  }
  show_attachment_modal(attachment, attachment_modal)
})

// When the user clicks off the image we should hide the modal
$(document).on('click', function (event) {
  const target = $(event.target)
  const attachment_modal = $('#trix_attachment_modal')
  // If we didn't click on the modal, or on an attachment, we should remove the modal
  if (
    !target.closest('[data-trix-attachment]').length &&
    !target.closest('#trix_attachment_modal').length
  ) {
    // If there is a modal currently visible we should update it before continuing.
    if (!attachment_modal.hasClass('hidden')) {
      update_attachment($(attachment_modal.find('input')), attachment_modal)
    }
    hide_attachment_modal(attachment_modal)
  }
})

// The attachment delete button blocks click events.
// We can use the trix-attachment-remove event to know when this happens.

$(document).on('trix-attachment-remove', function (event) {
  hide_attachment_modal($('#trix_attachment_modal'))
})
