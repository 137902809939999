$(document).on('turbo:load', function () {
  const calloutBanner = $('.callout-banner')

  if (calloutBanner.length > 0) {
    calloutBanner.on('click', '.callout-dismissable', function () {
      calloutBanner.css('animation', 'slide-up-out 1s')
      calloutBanner.on('animationend', function () {
        calloutBanner.remove()
      })
    })
  }
})
