// Track a page view for hubspot on turbo visit and not on load because
// hubspot will track the page initially loading.
// We don't need to set the url since it should get it from history.
// NOTE: Update this for a turbo upgrade.
$(document).on('turbo:visit', function () {
  const _hsq = window._hsq = window._hsq || []
  _hsq.push(['trackPageView'])
})

window.loadHubspot = function loadHubspot (clientId) {
  const el = document.createElement('script')
  el.setAttribute('id', 'hs-script-loader')
  el.setAttribute('type', 'text/javascript')
  el.setAttribute('async', true)
  el.setAttribute('defer', true)
  el.setAttribute('src', '//js.hs-scripts.com/' + clientId + '.js')
  document.body.append(el)
}
