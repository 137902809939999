/**
 * Calculates the positions for the bio-block
 * based on values set in app/assets/stylesheets/about.scss
 * @returns {String} value for bottom property
 */
function getAboutBottomValue () {
  let bottomValue = '-80%'
  if (window.innerWidth <= 1280) {
    bottomValue = '-75%'
  }
  return bottomValue
}

/**
 * Toggles the description field for the given image card.
 * @param {HTMLElement} section the image section that was clicked
 */

window.toggleDescription = function toggleDescription (section) {
  const descriptionSection = section.querySelector('.bio-block')

  if (descriptionSection.classList.contains('about-toggle')) {
    $(descriptionSection).animate({ bottom: 0 })
  } else {
    $(descriptionSection).animate({ bottom: getAboutBottomValue() })
  }

  $(descriptionSection).find('.toggle-caret').toggleClass('fa-caret-right fa-caret-down')

  // About toggle is the marker class for indicating whether to collapse or expand the description section
  $(descriptionSection).toggleClass('about-toggle')
  $(descriptionSection).toggleClass('panel-border-green-all')
}

// if the company/people description is mor than 1 paragraph then show the first and hide all others and show "Show More" link
window.hide_additional_p_tags = function hide_additional_p_tags (selector) {
  if ((selector.is(':visible')) && (selector.find('p').length > 1)) {
    // hide additional paragraphs and show the link more container
    selector.find('p:not(:first-child)').addClass('hidden')
    selector.find('.link-more-container').show()
  }
}

// If we resize the page, make sure there's no weird misalignment
$(window).resize(function () {
  $('.bio-block.about-toggle').css('bottom', getAboutBottomValue())
})
