import * as Highcharts from 'highcharts/highstock'
import * as Accessibility from 'highcharts/modules/accessibility'

// Setup the accessibility module for the stock chart
Accessibility(Highcharts)

// Ensure passed in value is a number or else default to 100
// then parse the number into integer
function stockChartBaseIndexInteger (value) {
  let baseIndexInteger = 100

  if (typeof value === 'number') {
    baseIndexInteger = parseInt(value)
  }

  return baseIndexInteger
}

// https://api.highcharts.com/highstock/lang.rangeSelectorFrom
// https://api.highcharts.com/highstock/lang.rangeSelectorTo
const languageOptions = {
  lang: {
    rangeSelectorFrom: 'From',
    rangeSelectorTo: 'To'
  }
}
Highcharts.setOptions(languageOptions)

window.createPerfChart = function createPerfChart (container, data, legendPos, baseIndexValue) {
  const baseIndexInteger = stockChartBaseIndexInteger(baseIndexValue)

  const legend = {
    itemStyle: {
      color: '#FFFFFF',
      fontWeight: 'bold',
      fontSize: '11px'
    },
    itemHoverStyle: {
      color: '#FFFFFF'
    },
    itemHiddenStyle: {
      color: '#757575',
      'text-decoration': 'none'
    },
    layout: 'horizontal',
    align: 'right',
    verticalAlign: 'top',
    enabled: true
  }

  const highchartsOptions = {
    chart: {
      backgroundColor: 'transparent'
    },
    tooltip: {
      split: false,
      borderRadius: 0,
      borderWidth: 1,
      backgroundColor: '#272727',
      borderColor: '#4E4E4E',
      style: {
        color: '#FFFFFF',
        padding: '15px',
        fontSize: '13px'
      },
      dateTimeLabelFormats: {
        hour: '%A, %b %e, %Y'
      },
      title: {
        text: 'Index Performance',
        align: 'left',
        x: 70,
        style: {
          color: '#FFFFFF',
          fontWeight: 'bold'
        },
        enable: true
      },
      shared: false,
      pointFormatter: function () {
        // this.change is meaning a percentage of change that this.y against to the baseIndexInteger.
        // This is a formula how the change % be calculated,
        // this.change = ((this.y - baseIndexInteger) / baseIndexInteger) * 100.
        const normalizedSeriesValue = ((100 + this.change) / 100.0 * baseIndexInteger).toFixed(2)

        // Displaying a circle and fill with the series color.
        const colorCircle = '<span style="color: ' + this.series.color + '; font: 20px blod; alignment-baseline: central">\u25CF </span>'

        // Displaying the series name and normalized series value.
        const seriesName = '<span style="alignment-baseline: central;">' + this.series.name + ': ' + '</span>'
        const seriesValue = '<span style="alignment-baseline: central;">' + normalizedSeriesValue + '</span>'

        return colorCircle + seriesName + seriesValue
      }
    },
    // remove the highcharts.com logo
    credits: {
      enabled: false
    },
    // if enabled allows to export image
    exporting: {
      enabled: false
    },
    scrollbar: {
      enabled: false
    },
    plotOptions: {
      series: {
        compare: 'percent',
        compareStart: true,
        compareBase: 0,
        showInNavigator: true,
        lineWidth: 2
      },
      line: {
        animation: false
      }
    },
    rangeSelector: {
      verticalAlign: 'top',
      inputBoxBorderColor: '#4E4E4E',
      inputBoxWidth: 80,
      inputBoxHeight: 24,
      inputBoxBackgroundColor: '#272727',
      buttons: [{
        type: 'year',
        text: '1y',
        count: 1
      }, {
        type: 'year',
        text: '2y',
        count: 2
      }, {
        type: 'year',
        text: '3y',
        count: 3
      }, {
        type: 'year',
        text: '5y',
        count: 5
      }, {
        type: 'year',
        text: '10y',
        count: 10
      }, {
        type: 'all',
        text: 'All'
      }],
      inputEditDateFormat: '%m-%d-%Y',
      buttonTheme: {
        fill: '#272727',
        stroke: '#4E4E4E',
        'stroke-width': 1,
        style: {
          color: '#FFFFFF',
          fontWeight: 'bold'
        },
        states: {
          hover: {
            fill: '#2EE56B',
            stroke: '#2EE56B',
            'stroke-width': 1,
            style: {
              color: '#343434'
            }
          },
          select: {
            fill: '#2EE56B',
            stroke: '#2EE56B',
            'stroke-width': 1,
            style: {
              color: '#343434'
            }
          },
          disabled: {
            fill: '#757575',
            stroke: '#4E4E4E',
            'stroke-width': 1,
            style: {
              color: '#D8D8D8',
              cursor: 'not-allowed'
            }
          }
        }
      },
      inputStyle: {
        color: '#FFFFFF',
        fill: '#272727'
      },
      labelStyle: {
        color: '#FFFFFF',
        fontSize: '15px',
        fontWeight: 'bold'
      }
    },
    legend,
    xAxis: {
      // does not show the vertical line where mouse is hovering
      crosshair: false,
      type: 'datetime',
      minRange: 3600 * 1000 * 24 * 30, // one month,
      title: {
        text: 'Date',
        style: {
          color: '#FFFFFF',
          fontSize: '13px',
          fontWeight: 'bold'
        }
      },
      labels: {
        style: {
          color: 'white',
          fontSize: '11px'
        }
      }
    },
    yAxis: {
      title: {
        text: 'Index Value ($)',
        style: {
          color: '#FFFFFF',
          fontSize: '13px',
          fontWeight: 'bold'
        }
      },
      opposite: false,
      labels: {
        formatter: function () {
          return (this.value * baseIndexInteger / 100.0) + baseIndexInteger
        },
        style: {
          color: '#FFFFFF',
          fontSize: '11px'
        }
      },
      gridLineColor: '#4E4E4E'
    },
    series: data
  }
  Highcharts.stockChart(container, highchartsOptions)
}
