// This code is based off the example provided for the active_storage guide to add animation
// for file upload: https://edgeguides.rubyonrails.org/active_storage_overview.html#example
// It was modified to work with our app by revert from ES6 to ES5 so that it can compile

addEventListener('direct-upload:initialize', function (event) {
  const target = event.target
  const detail = event.detail
  const id = detail.id
  const file = detail.file
  target.insertAdjacentHTML('beforebegin', '\n    <div id="direct-upload-' + id + '" class="direct-upload direct-upload--pending">\n      <div id="direct-upload-progress-' + id + '" class="direct-upload__progress" style="width: 0%"></div>\n      <span class="direct-upload__filename"></span>\n    </div>\n  ')
  target.previousElementSibling.querySelector('.direct-upload__filename').textContent = file.name
})

addEventListener('direct-upload:start', function (event) {
  const id = event.detail.id
  const element = document.getElementById('direct-upload-' + id)
  element.classList.remove('direct-upload--pending')
})

addEventListener('direct-upload:progress', function (event) {
  const _event$detail = event.detail
  const id = _event$detail.id
  const progress = _event$detail.progress
  const progressElement = document.getElementById('direct-upload-progress-' + id)
  progressElement.style.width = progress + '%'
})

addEventListener('direct-upload:error', function (event) {
  event.preventDefault()
  const _event$detail = event.detail
  const id = _event$detail.id
  const error = _event$detail.error
  const element = document.getElementById('direct-upload-' + id)
  element.classList.add('direct-upload--error')
  element.setAttribute('title', error)
})

addEventListener('direct-upload:end', function (event) {
  const id = event.detail.id
  const element = document.getElementById('direct-upload-' + id)
  element.classList.add('direct-upload--complete')
})
