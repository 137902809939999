
$(window).scroll(function (e) {
  // Must adjust the trigger point based on the page, since the "indices" page
  // has buttons at the top that take up more text. Did not explicitly check for
  // if URL includes "indices" here due to the site being named "syntax-indices"
  // TODO - don't check which page it is every time this check fires
  if (document.URL.includes('about_us')) {
    fixed_subheader_trigger = 85
  } else {
    fixed_subheader_trigger = 150
  };

  if ($(window).scrollTop() > fixed_subheader_trigger) {
    $('.tab-container').addClass('fixed')
  } else {
    $('.tab-container').removeClass('fixed')
  }
})

window.add_spinner = function add_spinner (selector, text_flag) {
  html = '<div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>'

  text_flag = text_flag != null ? text_flag : ''
  const overlay = $('<div />', { html: 'Loading Daily Returns', class: 'spinner-overlay' }).css({
    position: 'absolute',
    width: $(selector).width(),
    height: $(selector).height() / 2,
    zIndex: 5,
    background: 'rgba(255, 255, 255, 1)'
  }).appendTo($(selector))
  overlay.append(html)
}

window.remove_spinner = function remove_spinner (selector) {
  $(selector + ' > .spinner-overlay').remove()
}
