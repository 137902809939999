import Rails from '@rails/ujs'

// This code is based off the example provided for the trix editor:
// https://trix-editor.org/js/attachments.js
// It was modified to work with our app by including a CSRF token,
// and retrieving the file URL from the response so we can use
// active storage blobs.
// This is a generic implementation can be reused on any page by adding
// data-attachment-url to the form containing a trix editor.
$(document).ready(function () {
  $(document).on('trix-attachment-add', function (event) {
    // We want to do some additional changes to the attachment to change how it is rendered.
    // Upload the file if needed.
    if (event.originalEvent.attachment.file) {
      upload_file_attachment(event.originalEvent.attachment, event.originalEvent.target)
    }
  })

  function upload_file_attachment (attachment, editor) {
    function set_progress (progress) {
      attachment.setUploadProgress(progress)
    }

    function set_attributes (attributes) {
      attachment.setAttributes(attributes)
    }

    upload_file(attachment, set_progress, set_attributes, editor)
  }

  function upload_file (attachment, progress_callback, success_callback, editor) {
    const form_data = create_form_data(attachment.file)
    const xhr = new XMLHttpRequest()
    // The URL that is posted to is pulled from the data-trix-attachment-url
    // attribute that should be present on a form containing the trix-editor.
    const trix_form = $(editor).closest('form')
    // We use the XHR API, and directly build the request with the
    // rails CSRF token to be able to display upload progress.
    xhr.open('POST', trix_form.data('trix-attachment-url'), true)

    xhr.upload.addEventListener('progress', function (event) {
      const progress = event.loaded / event.total * 100
      progress_callback(progress)
    })

    xhr.addEventListener('load', function (event) {
      if (xhr.status == 200) {
        // The app will return a json response with rails_attachment_id, url, and href
        // We use the rails_attachment_id to identify an image for deletion.
        // url is the image used for the img tag.
        // href is the href location of the a tag when you click on the image.
        success_callback(JSON.parse(xhr.response))
      } else {
        // If the attachment fails to upload, it should be removed from the editor.
        attachment.remove()
      }
    })

    xhr.send(form_data)
  }

  function create_form_data (file) {
    const data = new FormData()
    data.append('Content-Type', file.type)
    data.append('_method', 'PATCH')
    data.append('authenticity_token', Rails.csrfToken())
    data.append('blog_post[attachment]', file)
    return data
  }
})
